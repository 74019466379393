import React from 'react';
import { Helmet } from 'react-helmet';
// import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Scrollbar } from 'swiper';
// import 'swiper/components/scrollbar/scrollbar.scss';
// import 'swiper/swiper.scss';
// import IconStar from '~/components/atoms/IconStar'
import FadeInWhenVisible from '~/components/FadeInWhenVisible'

export default function Reviews({ key, index, ...props }) {

  SwiperCore.use([Scrollbar]);

  const section = props.reviews
  const rating = String(section.rating ?? '4,9').replace('.', ',');

  const ref = React.useRef(null);
  React.useEffect(() => {
    if (window.Trustpilot) {
      window.Trustpilot.loadFromElement(ref.current, true);
    }
  }, []);

  // Comment out the return statement commented on 2024-07-31
  /*
  return (
    <FadeInWhenVisible id={section.fieldGroupName + index} className="py-28 service-swiper overflow-hidden bg-sand">
      <div className="container">
        <FadeInWhenVisible>
          <h5 className="text-h5 mb-12 text-center">{section.heading}</h5>
        </FadeInWhenVisible>
        <FadeInWhenVisible>
          <div
            ref={ref}
            className="trustpilot-widget"
            data-locale="en-GB"
            data-template-id="54ad5defc6454f065c28af8b"
            data-businessunit-id="579f04e80000ff000592f5f7"
            data-style-height="250px"
            data-style-width="100%"
            data-theme="light"
            data-tags={section.type}
            data-stars="3,4,5"
            data-review-languages="en"
            data-font-family="Roboto"
            data-text-color="#12203A"
          >
            <a href="https://uk.trustpilot.com/review/cityrelay.com" target="_blank" rel="noopener">See more reviews</a>
          </div>
        </FadeInWhenVisible>
      </div>
      <Helmet>
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org/",
              "@type": "LocalBusiness",
              "name": "City Relay",
              "url": "https://cityrelay.com/",
              "telephone":"+442033186268",
              "address": {
                "@type": "PostalAddress",
                "streetAddress": "First Floor, 10 Cromwell Pl, South Kensington",
                "addressLocality": "London",
                "postalCode": "SW7 2JN",
                "addressCountry": "UK"
              },
              "aggregateRating": {
                "@type": "AggregateRating",
                "itemReviewed": {
                  "@type": "Organization",
                  "name": "City Relay",
                  "url": "https://cityrelay.com/"
                },
                "ratingValue": "${rating}",
                "ratingCount": "${section.count}",
                "reviewCount": "${section.count}",
                "bestRating": "5",
                "worstRating": "1"
              }
            }
          `}
        </script>
      </Helmet>
    </FadeInWhenVisible>
  );
  */

  // Return null to render nothing
  return null;
}
